<template>
    <div class="table-wraper" :class="{ 'hidden': !displayTable }">
        <h2>Calculation result</h2>
        <div class="calc-results">

            <div class="bill">

                <div class="inputFields">
                    <div class="loadType inputFieldsCol">
                        <div class="inputLabel">{{results ? results.loadTypeAquabox : 'Aquabox ST'}}</div>
                        <select @change="detectChange()" v-model="AquaboxST" type="text" name="loadType" class="">   
                            <option selected value="Pedestrian">Pedestrian</option>
                            <option value="Pedestrian and Cars">Pedestrian and Cars</option>
                            <option value="SLW30 (light trucks)">SLW30 (light trucks)</option>
                            <option value="SLW60 (heavy trucks)">SLW60 (heavy trucks)</option>
                        </select>
                    </div>
                    <div class="layout inputFieldsCol">
                        <div class="inputLabel">Levels</div>
                        <select @change="detectChange()" v-model="Levels" type="text" name="layout" class="">   
                            <option selected value="1">1</option>
                            <option value="1.5">1.5</option>
                            <option value="2">2</option>
                            <option value="2.5">2.5</option>
                            <option value="3">3</option>
                        </select>
                    </div>
                </div>

                <table class="levels">
                    <tbody>
                        <tr class="no-border">
                            <td class="result-label">Aquabox Levels</td>
                            <td class="result-value" colspan="3">{{results ? results.dropdownAquaboxLevels : '1'}}</td>
                        </tr>
                        <tr>
                            <td class="result-label">Aquabox Cube Levels</td>
                            <td class="result-value" colspan="3">{{results ? results.dropdownCubeLevels : '0'}}</td>
                        </tr>
                    </tbody>
                </table>

                <div class="inputFields">
                    <div class="TypeOfTank inputFieldsCol">
                        <div class="inputLabel">Type of Tank</div>
                        <select @change="detectChange()" v-model="TypeOfTank" type="text" name="TypeOfTank" class="">   
                            <option selected value="Dispersing">Dispersing</option>
                            <option value="Impermeable">Impermeable</option>
                        </select>
                    </div>
                </div>

                <table>
                    <tbody>
                        <tr class="tbl-title">
                            <td colspan="4"><strong>BILL OF QUANTITIES</strong></td>
                        </tr>
                        <tr>
                            <td class="result-label">{{results ? results.loadTypeAquabox : 'Aquabox ST'}}</td>
                            <td class="result-value result-value-left" colspan="3">{{results ? results.aquaboxST : '/'}} pcs</td>
                        </tr>
                        <tr>
                            <td class="result-label">Aquabox Sidewall Grids</td>
                            <td class="result-value result-value-left" colspan="3">{{results ? results.aquaboxSidewallGrids : '/'}} pcs</td>
                        </tr>
                        <tr>
                            <td class="result-label">Caps</td>
                            <td class="result-value result-value-left" colspan="3">{{results ? results.caps : '/'}} pcs</td>
                        </tr>
                        <tr>
                            <td class="result-label">{{results ? results.loadTypeCube : 'Aquabox Cube ST'}}</td>
                            <td class="result-value result-value-left" colspan="3">{{results ? results.aquaboxCubeST : '/'}} pcs</td>
                        </tr>
                        <tr>
                            <td class="result-label">Cube Sidewall Grids</td>
                            <td class="result-value result-value-left" colspan="3">{{results ? results.cubeSidewallGrids : '/'}} pcs</td>
                        </tr>
                        <tr>
                            <td class="result-label">D400 Caps</td>
                            <td class="result-value result-value-left" colspan="3">{{results ? results.d400Caps : '/'}} pcs</td>
                        </tr>
                        <tr>
                            <td class="result-label">Double Joints*</td>
                            <td class="result-value result-value-left" colspan="3">{{results ? results.doubleJoints : '/'}} pcs</td>
                        </tr>
                        <tr>
                            <td class="result-label">Single Joints</td>
                            <td class="result-value result-value-left" colspan="3">{{results ? results.singleJoints : '/'}} pcs</td>
                        </tr>
                        <tr>
                            <td class="result-label">D4 Connector</td>
                            <td class="result-value result-value-left" colspan="3">{{results ? results.d4Connector : '/'}} pcs</td>
                        </tr>
                        <tr>
                            <td class="result-label">O Ring</td>
                            <td class="result-value result-value-left" colspan="3">{{results ? results.oRing : '/'}} pcs</td>
                        </tr>
                        <tr>
                            <td class="result-label">Currugated Pipe Ø400</td>
                            <td class="result-value result-value-left" colspan="3">{{results ? results.currugatedPipe400 : '/'}} pcs</td>
                        </tr>
                        <tr>
                            <td class="result-label">Impermeable Liner</td>
                            <td v-if="units=='metric'" class="result-value result-value-left" colspan="3">{{results ? results.impermeableLiner : '/'}} m<sup>2</sup></td>
                            <td v-if="units=='imperial'" class="result-value result-value-left" colspan="3">{{results ? results.impermeableLinerSft : '/'}} sft</td>
                        </tr>
                        <tr>
                            <td class="result-label">Geotextile</td>
                            <td v-if="units=='metric'" class="result-value result-value-left" colspan="3">{{results ? results.geotextile : '/'}} m<sup>2</sup></td>
                            <td v-if="units=='imperial'" class="result-value result-value-left" colspan="3">{{results ? results.geotextileSft : '/'}} sft</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- <div class="aquabox">
                <table>
                    <tbody>
                        <tr class="tbl-title">
                            <td colspan="4"><strong>AQUABOX</strong></td>
                        </tr>
                        <tr>
                            <td class="result-label">Aquabox 0A</td>
                            <td class="result-value" colspan="3">{{results ? results.a0 : '/'}} pcs</td>
                        </tr>
                        <tr>
                            <td class="result-label">Aquabox 1A</td>
                            <td class="result-value" colspan="3">{{results ? results.a1 : '/'}} pcs</td>
                        </tr>
                        <tr>
                            <td class="result-label">Aquabox 2A</td>
                            <td class="result-value" colspan="3">{{results ? results.a2 : '/'}} pcs</td>
                        </tr>
                        <tr>
                            <td class="result-label">Aquabox 3A</td>
                            <td class="result-value" colspan="3">{{results ? results.a3 : '/'}} pcs</td>
                        </tr>
                        <tr>
                            <td class="result-label">Aquabox 4A</td>
                            <td class="result-value" colspan="3">{{results ? results.a4 : '/'}} pcs</td>
                        </tr>
                        <tr>
                            <td class="result-label">Aquabox 0C - inspection</td>
                            <td class="result-value" colspan="3">{{results ? results.c0Inspection : '/'}} pcs</td>
                        </tr>
                        <tr>
                            <td class="result-label">Aquabox 1C - inspection</td>
                            <td class="result-value" colspan="3">{{results ? results.c1Inspection : '/'}} pcs</td>
                        </tr>
                        <tr>
                            <td class="result-label">Aquabox 2C - inspection</td>
                            <td class="result-value" colspan="3">{{results ? results.c2Inspection : '/'}} pcs</td>
                        </tr>
                        <tr>
                            <td class="result-label">Aquabox 3C - inspection</td>
                            <td class="result-value" colspan="3">{{results ? results.c3Inspection : '/'}} pcs</td>
                        </tr>
                        <tr>
                            <td class="result-label">Aquabox 4C - inspection</td>
                            <td class="result-value" colspan="3">{{results ? results.c4Inspection : '/'}} pcs</td>
                        </tr>
                        <tr>
                            <td class="result-label">Aquabox 0C - levels</td>
                            <td class="result-value" colspan="3">{{results ? results.c0 : '/'}} pcs</td>
                        </tr>
                        <tr>
                            <td class="result-label">Aquabox 1C - levels</td>
                            <td class="result-value" colspan="3">{{results ? results.c1 : '/'}} pcs</td>
                        </tr>
                        <tr>
                            <td class="result-label">Aquabox 2C - levels</td>
                            <td class="result-value" colspan="3">{{results ? results.c2 : '/'}} pcs</td>
                        </tr>
                        <tr>
                            <td class="result-label">Aquabox 3C - levels</td>
                            <td class="result-value" colspan="3">{{results ? results.c3 : '/'}} pcs</td>
                        </tr>
                        <tr>
                            <td class="result-label">Aquabox 4C - levels</td>
                            <td class="result-value" colspan="3">{{results ? results.c4 : '/'}} pcs</td>
                        </tr>
                    </tbody>
                </table>
            </div> -->
            <div>
                <div class="structure">
                    <table>
                        <tbody>
                            <tr class="tbl-title">
                                <td colspan="4"><strong>STRUCTURE DIMENSIONS</strong></td>
                            </tr>
                            <tr>
                                <td class="result-label">Tank Height</td>
                                <td v-if="units=='metric'" class="result-value result-value-left" colspan="3">{{results ? results.tankHeight : '/'}} m</td>
                                <td v-if="units=='imperial'" class="result-value result-value-left" colspan="3">{{results ? results.tankHeightFt : '/'}} ft</td>
                            </tr>
                            <tr>
                                <td class="result-label">Tank Surface</td>
                                <td v-if="units=='metric'" class="result-value result-value-left" colspan="3">{{results ? results.tankSurface : '/'}} m<sup>2</sup></td>
                                <td v-if="units=='imperial'" class="result-value result-value-left" colspan="3">{{results ? results.tankSurfaceSft : '/'}} sft</td>
                            </tr>
                            <tr>
                                <td class="result-label">Gross Volume</td>
                                <td v-if="units=='metric'" class="result-value result-value-left" colspan="3">{{results ? results.grossVolume : '/'}} m<sup>3</sup></td>
                                <td v-if="units=='imperial'" class="result-value result-value-left" colspan="3">{{results ? results.grossVolumeCft : '/'}} cft</td>
                            </tr>
                            <tr>
                                <td class="result-label">Net Storage Volume</td>
                                <td v-if="units=='metric'" class="result-value result-value-left" colspan="3">{{results ? results.netStorageVolume : '/'}} m<sup>3</sup></td>
                                <td v-if="units=='imperial'" class="result-value result-value-left" colspan="3">{{results ? results.netStorageVolumeCft : '/'}} cft</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="stones">
                    <table>
                        <tbody>
                            <tr class="tbl-title">
                                <td colspan="4"><strong>COVERING OF CLEAR STONES</strong></td>
                            </tr>
                            <tr>
                                <td class="result-label">Lateral thickness of Stone</td>
                                <td v-if="units=='metric'" class="result-value result-value-left" colspan="3">{{results ? results.lateralThicknessOfStone : '/'}} m</td>
                                <td v-if="units=='imperial'" class="result-value result-value-left" colspan="3">{{ results ? results.lateralThicknessOfStoneFt : '/' }} ft</td>
                            </tr>
                            <tr>
                                <td class="result-label">Top thickness of Stone</td>
                                <td v-if="units=='metric'" class="result-value result-value-left" colspan="3">{{results ? results.topThicknessOfStone : '/'}} m</td>
                                <td v-if="units=='imperial'" class="result-value result-value-left" colspan="3">{{results ? results.topThicknessOfStoneFt : '/'}} ft</td>
                            </tr>
                            <tr>
                                <td class="result-label">Bottom thickness of Stone</td>
                                <td v-if="units=='metric'" class="result-value result-value-left" colspan="3">{{results ? results.bottomThicknessOfStone : '/'}} m</td>
                                <td v-if="units=='imperial'" class="result-value result-value-left" colspan="3">{{results ? results.bottomThicknessOfStoneFt : '/'}} ft</td>
                            </tr>
                            <tr>
                                <td class="result-label">Volume of Stone</td>
                                <td v-if="units=='metric'" class="result-value result-value-left" colspan="3">{{results ? results.volumeOfStone : '/'}} m<sup>3</sup></td>
                                <td v-if="units=='imperial'" class="result-value result-value-left" colspan="3">{{results ? results.volumeOfStoneCft : '/'}} cft</td>
                            </tr>
                            <tr>
                                <td class="result-label">Net Storage Volume</td>
                                <td v-if="units=='metric'" class="result-value result-value-left" colspan="3">{{results ? results.netStorageVolumeStone : '/'}} m<sup>3</sup></td>
                                <td v-if="units=='imperial'" class="result-value result-value-left" colspan="3">{{results ? results.netStorageVolumeStoneCft : '/'}} cft</td>
                            </tr>
                            <tr>
                                <td class="result-label">Impermeable Liner</td>
                                <td v-if="units=='metric'" class="result-value result-value-left" colspan="3">{{results ? results.impermeableLinerStone : '/'}} m<sup>2</sup></td>
                                <td v-if="units=='imperial'" class="result-value result-value-left" colspan="3">{{results ? results.impermeableLinerStoneSft : '/'}} sft</td>
                            </tr>
                            <tr>
                                <td class="result-label">Geotextile</td>
                                <td v-if="units=='metric'" class="result-value result-value-left" colspan="3">{{results ? results.geotextileStone : '/'}} m<sup>2</sup></td>
                                <td v-if="units=='imperial'" class="result-value result-value-left" colspan="3">{{results ? results.geotextileStoneSft : '/'}} sft</td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="additional">
                        <tbody>
                            <tr class="tbl-title">
                                <td colspan="4"><strong>NOTE</strong></td>
                            </tr>
                            <tr>
                                <td class="result-label">If levels = 1.5 D4 additional (Bottom level)</td>
                                <td class="result-value" colspan="3">{{results ? results.D4Additional : '/'}} pcs</td>
                            </tr>
                        </tbody>
                    </table>
                    <button @click="showPreview()" class="btn open-pdf">Preview</button>
                </div>
            </div>
        </div>
        
        <pdf-export :results="results" :aquaboxST="AquaboxST" :levels="Levels" :typeOfTank="TypeOfTank" :isShowing="isShowing" @closePdfPreview="isShowing = false"></pdf-export>
        
    </div>

</template>

<script>

import PdfExport from './PdfExport.vue'

export default {
  name: 'result-table',
  
    components: {PdfExport},

  props: {
    displayTable: false,
    results: null,
    units: "metric"
  },
    
  data(){
    return {
        AquaboxST: 'Pedestrian',
        Levels: '1',
        TypeOfTank: 'Dispersing',
        isShowing: false
        }  
    },

    methods: {
        detectChange(){
            this.$emit('dropdownChange', {
                AquaboxST: this.AquaboxST,
                Levels: this.Levels,
                TypeOfTank: this.TypeOfTank
            })
        },
        showPreview(){
            this.emitter.emit('getSnapshot', null)
            this.isShowing ^= true;
        }
    }

};
</script>
<style scoped>

</style>

